// This value (150 by default) determines whether a color is dark or light.
// This is used to determine the text color based on background color.
// Our primary color is light and we need light text, so we set this a bit higher than normal.
$yiq-contrasted-threshold: 180;

// Change the theme colors
$theme-colors: (
        "primary": #093376,
        "secondary": #FA5B0F,
        "info": #555555,
        "light":#fff,
        "danger":#DE9035,
);
$enable-transitions: true;

// Import Bootstrap and its default variables
// It is important to do this at the end and not in the beginning.
// Putting this in the beginning will cause our custom values to be overridden by the library's values.
@import './../node_modules/bootstrap/scss/bootstrap';


.user__list {
  padding: 16px 0;
  display: flex;

  .user__list__item {
    cursor: pointer;
    margin: 0 16px;
    width: 200px;
    padding: 16px;
    border: 1px solid black;

    &.active {
      background: blue;
      color: white;
    }
  }
}

.screen-shoot__list {

}

.screen-shot__container {
  display: flex;
  flex-wrap: wrap;

  .screen-shot__item {

    margin: 16px;

    img {
      height: auto;
      width: 350px;
      object-fit: contain;
    }
  }
}

.link {
  cursor: pointer;
  color: blue;
}
