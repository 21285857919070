.date-range-wrapper {
  background: white;
  display: flex;
  align-items: center;
  padding: 8px;
  min-width: 250px;

  .date-range-input {
    border: none;
    padding: 4px;

  }

  .date-range-icon {
    color: var(--color-secondary);
  }
}
